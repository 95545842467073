import React from 'react'

// material-ui
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Logo from '../assets/images/logo.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '20px auto',
    padding: theme.spacing(3),
    width: '40%'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3)
  }
}));

const Success = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.imgContainer}>
        <img src={Logo} alt="Company Logo" />
      </div>
      <Typography variant="subtitle2" style={{ textAlign: 'center' }}>We would like to thank you for the payment and for choosing ICE.</Typography>
      <Typography variant="subtitle2" style={{ textAlign: 'center' }}>Should you require any further information just email us at <a href="mailto:info@iceireland.com">info@iceireland.com</a></Typography>
    </Paper>
  )
}

export default Success
