import React, { useState } from 'react';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { NumberFormatValues } from 'react-number-format';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';

// material-ui
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// components
import { FormNumberField, FormTextField, MUINumberField } from '../components';

// images
import Logo from '../assets/images/logo.png';
import StripeLogo from '../assets/images/stripe.png';

const size = 768;
const ccFee = 0.025;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    // marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    width: '40%',
    [theme.breakpoints.down(size)]: {
      margin: 'auto auto',
      width: 'auto'
      // padding: 0
    }
  },
  dev: {
    border: '3px solid red',
    borderRadius: '5px',
    color: 'red',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3)
  },
  img: {
    [theme.breakpoints.down(size)]: {
      width: '100%'
    }
  },
  inputContainer: {
    display: 'flex',
    flexFlow: 'column',
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(3)
    }
  },
  divider: {
    height: theme.spacing(2)
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  subTitle: {
    marginBottom: theme.spacing(2)
  }
}));

interface IPayment {
  amount: number;
  course: string;
  email: string;
  familyName: string;
  firstName: string;
  studentName: string;
}

const initialValues: IPayment = {
  amount: 0,
  course: '',
  email: '',
  familyName: '',
  firstName: '',
  studentName: ''
};

const validationSchema = Yup.object().shape({
  amount: Yup.number().required('Amount is required'),
  course: Yup.string().required('Course is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid Email address'),
  firstName: Yup.string().required('First Name is required'),
  familyName: Yup.string().required('Family Name is required'),
  studentName: Yup.string().required('Student Name is required')
});

declare global {
  interface Window {
    Stripe: any;
  }
}

const Home = () => {
  const classes = useStyles();
  const [cardFee, setCardFee] = useState(0);
  const { addToast } = useToasts();

  const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK);

  const onAmountChangedHandler = ({ floatValue }: NumberFormatValues) => {
    setCardFee(floatValue * ccFee);
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.imgContainer}>
          <img className={classes.img} src={Logo} alt="Company Logo" />
        </div>
        <Typography className={classes.title} variant="h6" component="h1">
          Payment Portal
        </Typography>
        <Typography
          className={classes.subTitle}
          variant="caption"
          component="h2"
        >
          * Please Note: Card Payments are subject to a charge of 2.5% of the
          amount and will be automatically added to the payment.
        </Typography>
        {process.env.REACT_APP_NODE_ENV === 'staging' && (
          <div className={classes.dev}>
            <Typography variant="subtitle1">
              ***** DEVELOPMENT VERSION *****
            </Typography>
          </div>
        )}
        <Formik
          initialValues={initialValues}
          validateOnChange={true}
          validationSchema={validationSchema}
          onSubmit={async (values: IPayment) => {
            const fee = +cardFee.toFixed(2);
            try {
              const session = await axios.post('api/stripe/checkout', {
                ...values,
                amount: +((values.amount + fee) * 100).toFixed(2)
              });

              stripe
                .redirectToCheckout({
                  sessionId: session.data.id
                })
                .then((result: any) => {
                  if (result.error) {
                    addToast(result.error.message, {
                      appearance: 'error',
                      autoDismiss: true
                    });
                  }
                });
            } catch (err) {
              if (err.response && err.response.data) {
                const content = (
                  <div>
                    Code: {err.response.data.code}
                    <br />
                    <br />
                    {err.response.data.message}
                  </div>
                );
                addToast(content, { appearance: 'error', autoDismiss: true });
              }
            }
          }}
        >
          {({ values, isSubmitting, isValid }) => (
            <Form>
              <section className={classes.inputContainer}>
                <FormTextField label="First Name" required name="firstName" />
                <FormTextField label="Family Name" name="familyName" required />
                <FormTextField label="Email" name="email" required />
                <FormTextField
                  label="Student Name"
                  name="studentName"
                  required
                />
                <FormTextField label="Course" name="course" required />
                <FormNumberField
                  decimalScale={2}
                  fixedDecimalScale
                  label="Amount (in Euro)"
                  name="amount"
                  required
                  startAdornment="€"
                  onValueChange={onAmountChangedHandler}
                />
                <MUINumberField
                  decimalScale={2}
                  disabled
                  fixedDecimalScale
                  label="Card Fee (2.5%)"
                  startAdornment="€"
                  value={cardFee}
                />
                <Button
                  color="primary"
                  disabled={!values.amount || isSubmitting || !isValid}
                  type="submit"
                  variant="contained"
                >
                  Pay €
                  {values.amount
                    ? (values.amount + cardFee).toFixed(2)
                    : '0.00'}
                </Button>
              </section>
            </Form>
          )}
        </Formik>
        <div className={classes.divider} />
        <a href="https://www.stripe.com">
          <img src={StripeLogo} alt="Stripe Logo" height="50px" />
        </a>
      </Paper>
      <section className={classes.root}>
        <div className="links">
          <a className={classes.link} href="https://www.iceireland.com/">
            <Typography variant="caption" color="primary">
              Home
            </Typography>
          </a>
          <span> | </span>
          <a
            className={classes.link}
            href="https://www.iceireland.com/contact-us/"
          >
            <Typography variant="caption" color="primary">
              Contact Us
            </Typography>
          </a>
        </div>
      </section>
    </>
  );
};

export default Home;
