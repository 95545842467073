import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { MUITextFieldProps } from './';

const useStyles = makeStyles({
  required: {
    color: 'red'
  }
});

const MUITextField: React.FC<MUITextFieldProps> = ({
  children,
  fullWidth = true,
  label,
  required,
  startAdornment,
  ...rest
}) => {
  const classes = useStyles();

  label = label || '';

  return (
    <TextField
      {...rest}
      fullWidth={fullWidth}
      label={
        required ? (
          <>
            {label}
            <span className={classes.required}> *</span>
          </>
        ) : (
            `${label}`
          )
      }
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        )
      }}
      variant="outlined"
    />
  );
};

export default MUITextField;
