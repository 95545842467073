import React from 'react';
import { Field, FieldProps } from 'formik';
import { NumberFormatProps, NumberFormatValues } from 'react-number-format';

import { MUINumberField } from '../';

interface FormNumberFieldProps extends NumberFormatProps {
  name: string;
}

const FormNumberField: React.FC<FormNumberFieldProps> = ({ name, onValueChange, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const error = !!form.errors[name] && !!form.touched[name];
        const { setFieldValue } = form;

        const onChangeHandler = (e: NumberFormatValues) => {
          setFieldValue(name, e.floatValue);

          if (onValueChange) {
            onValueChange(e);
          }
        };

        return (
          <MUINumberField
            {...field}
            {...rest}
            onValueChange={onChangeHandler}
            error={error}
            helperText={error ? form.errors[name] : ''}
          />
        );
      }}
    </Field>
  );
};

export default FormNumberField;
