import React from 'react';
import { Field, FieldProps } from 'formik';

// components
import { MUITextField, MUITextFieldProps } from '../';

interface FormTextFieldProps extends MUITextFieldProps {
  name: string;
}

const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  variant = 'outlined',
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const error = !!form.errors[name] && !!form.touched[name];
        return (
          <>
            <MUITextField
              {...field}
              {...rest}
              value={field.value ? field.value : ''}
              error={error}
              helperText={error ? form.errors[name] : ''}
            />
          </>
        );
      }}
    </Field>
  );
};

export default FormTextField;
