import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

// material-ui
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Home from '../pages/Home';
import Success from '../pages/Success';

// Styles
const theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#60ad5e',
      main: '#2e7d32',
      dark: '#005005',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffd95b',
      main: '#ffa726',
      dark: '#c77800',
      contrastText: '#000000'
    }
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <Router>
          <Route component={Home} exact path="/" />
          <Route component={Success} exact path="/success" />
        </Router>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
