import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { MUITextField } from './';

const MUINumberField: React.FC<NumberFormatProps> = ({ onChange, ...rest }) => {
  return <NumberFormat {...rest} customInput={MUITextField} />;
};

export default MUINumberField;
